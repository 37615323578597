export const columns = [
	{
		prop: 'name',
		label: '订单来源',
	},
	{
		prop: 'status',
		label: '来源状态',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 1 ? '启用' : '禁用'
		},
	},
	/* {
		prop: 'rolePermissions',
		label: '联系人',
		formatter: function (row, column, cellValue, index) {
			return cellValue === 10 ? '个人' : '公司'
		},
	}, */
]

export const filters = {
	expand: false,
	filters: [
		{
			label: '订单来源',
			attr: 'name',
			type: 'search',
			placeholder: '请输入订单来源名称',
			clearable: true,
		},
		{
			attr: 'status',
			label: '来源状态',
			type: 'select',
			placeholder: '请选择状态',
			options: [
				{ name: '启用', code: 1 },
				{ name: '禁用', code: 0 },
			],
		},
	],
}

export const rules = {
	name: [{ required: true, message: '请输入名称', trigger: 'blur' }],
	status: [{ required: true, message: '请选择状态', trigger: 'blur' }],
}
