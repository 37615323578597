<template>
	<div class="page-container">
		<yi-list-layout :loading="isLoading">
			<template #title>
				<div class="header-title">{{ pageTitle }}</div>
				<div class="header-button">
					<el-button type="success" @click="onOpenDialog('create')">
						<el-icon class="icon-plus"><Plus /></el-icon>
						新增
					</el-button>
				</div>
			</template>
			<template #filter>
				<yi-filter ref="filterRef" :setting="filterSetting" @change="onChangeFilter" />
			</template>
			<template #table>
				<yi-table
					ref="yiTableRef"
					table-height="100%"
					show-no
					:columns="columnSetting"
					:data="tableData"
					:params="{ page }"
				>
					<template #action>
						<el-table-column width="240" label="操作" fixed="right">
							<template #default="{ row }">
								<el-link
									type="primary"
									style="margin-right: 15px"
									:underline="false"
									@click="onOpenDialog('update', row)"
								>
									编辑
								</el-link>
								<!-- <el-link
									type="danger"
									style="margin-right: 15px"
									:underline="false"
									@click="onOpenDialog('delete', row)"
								>
									删除
								</el-link> -->
							</template>
						</el-table-column>
					</template>
				</yi-table>
			</template>
			<template #footer>
				<yi-pagination
					:current-page="page.current"
					:page-size="page.size"
					:total-count="totalCount"
					@page-change="onPageChange"
					@size-change="onSizeChange"
				/>
			</template>
		</yi-list-layout>
		<form-dialog ref="formDialogRef" @refresh="onRefreshData" />
	</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'OrderType',
})
</script>

<script setup name="OrderType">
import Request from '@/http'
import { inject, ref, getCurrentInstance, onMounted } from 'vue'
import { filters, columns } from './config'
import FormDialog from './components/Form'
import usePage from '@/hooks/usePage'

const pageTitle = '订单来源'

const instance = getCurrentInstance()

const $message = inject('$message')

const formDialogRef = ref(null)

const filterSetting = ref(filters)
const columnSetting = ref(columns)

const {
	isLoading,
	filterParams,
	totalCount,
	page,
	tableData,
	onRefreshData,
	onChangeFilter,
	onSizeChange,
	onPageChange,
} = usePage(queryList)

function queryList() {
	isLoading.value = true
	const params = {
		...filterParams.value,
		page: page.value,
	}
	Request({
		url: 'suf4-user-service/orderSource/paginate',
		method: 'POST',
		data: params,
	})
		.then(res => {
			if (res.code === 200) {
				isLoading.value = false
				tableData.value = res.data.records
				totalCount.value = res.data.total
				instance.refs.yiTableRef.scrollTop()
				console.log('列表获取成功 ==>', tableData)
			} else {
				isLoading.value = false
				$message.error(res.msg)
			}
		})
		.catch(error => {
			isLoading.value = false
			$message.error(error.message)
		})
}

function onOpenDialog(type, item) {
	switch (type) {
		case 'create':
			instance.refs.formDialogRef.create()
			break
		case 'update':
			instance.refs.formDialogRef.update(item)
			break
	}
}

function initPage() {
	onRefreshData()
}

onMounted(async () => {
	initPage()
})
</script>

<style lang="less" scoped>
.page-container {
	width: 100%;
	height: 100%;
	overflow: hidden;
	.icon-plus {
		margin-right: 5px;
	}
	.header-title {
		height: 54px;
		line-height: 54px;
		overflow: hidden;
		float: left;
	}
	.header-button {
		margin: 11px 0;
		float: right;
	}
}
</style>
